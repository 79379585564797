<template>
    <div class="loginView">
        <a href="/" class="smallLogo"></a>
        <div class="loginViewInner">
            <div style="flex-grow: 1;" class="align-self-center">
                <blockquote>
                    {{ typedText }}
                </blockquote>
                <p class="blockquoteAuthor">
                    {{ author }}
                </p>
            </div>
            <div class="align-self-center">
                <b-form @submit="login">
                    <b-form-group>
                        <div class="logoContainer">
                            <img src="../assets/img/logo_poolinq_white.svg">
                            <div class="spacer xs"></div>
                            <p><strong>Work</strong>space</p>
                        </div>
                        <div class="spacer xl"></div>
                        <label :class="errorOuccured()"> {{ $t('login.username') }}</label>
                        <div class="spacer xs"></div>
                        <b-form-input v-model="email" type="email" placeholder="Enter email"></b-form-input>
                        <div class="spacer m"></div>
                        <label :class="errorOuccured()">{{ $t('login.password') }}</label>
                        <div class="spacer xs"></div>
                        <b-form-input v-model="password" type="password" :placeholder="$t('login.enterPassword')"></b-form-input>
                        <div class="spacer m"></div>
                        <p v-show="error" class="advice">{{ errorMsg }}</p>
                        <b-button class="loginButton" type="submit" variant="primary">{{ $t('login.login') }}</b-button>
                        <div class="spacer l"></div>
                        <router-link class="forgotPassword" to="/forgotpassword">
                            <!--
                            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.817 14.295c.082-.734.408-1.374.977-1.92l.91-.865c.71-.689 1.209-1.316 1.493-1.881a3.955 3.955 0 0 0 .427-1.803c0-1.4-.438-2.484-1.314-3.251-.876-.767-2.108-1.151-3.695-1.151-1.572 0-2.813.404-3.723 1.213-.91.808-1.372 1.928-1.387 3.358h3.257c.015-.6.19-1.07.522-1.41.334-.34.777-.51 1.331-.51 1.168 0 1.752.632 1.752 1.897 0 .42-.112.818-.337 1.196-.224.378-.675.869-1.353 1.471-.678.603-1.144 1.215-1.398 1.837-.255.621-.382 1.456-.382 2.504h2.188c.387 0 .707-.3.732-.685zm-1.426 5.84c.546 0 .986-.161 1.32-.483.332-.322.499-.73.499-1.224 0-.502-.17-.916-.511-1.241-.34-.326-.777-.489-1.308-.489-.532 0-.968.163-1.309.489-.34.325-.51.74-.51 1.24 0 .495.166.903.499 1.225.333.322.773.483 1.32.483z" fill="#0CBA4A" fill-rule="nonzero"/>
                            </svg>
                            -->
                            <span>{{ $t('login.forgotPassword') }}</span>
                        </router-link>    
                    </b-form-group>
                </b-form>
            </div> 
        </div>       
     </div> 
</template>
<script>
    import DataService from '@/services/StrapiService'
    import { mapGetters } from 'vuex'
    export default {
        name: 'Login',
        
        data() {
            return {
                email: '',
                password: '',
                error: false,
                errorMsg: this.$t('login.loginError'),
                strapiUrl: DataService.getServerUrl(),
                typedText: "",
                author: "",
                quotes: [
                    {
                        quote: 'Wer nicht liebt Wein, Weib und Gesang, der bleibt ein Narr sein Leben lang',
                        author: "Martin Luther"
                    },
                    {
                        quote: 'In vino veritas.',
                        author: "Plinius der Ältere"
                    },
                    {
                        quote: 'Wein ist der Beweis, dass Gott uns liebt und uns glücklich sehen will.',
                        author: "Benjamin Franklin"
                    },
                    {
                        quote: 'Das Leben ist zu kurz, um schlechten Wein zu trinken.',
                        author: "Johann Wolfgang von Goethe"
                    },
                    {
                        quote: 'Wein ist die edelste Verkörperung des Naturgeistes',
                        author: "Friedrich Hebbel"
                    },
                    {
                        quote: 'Der Wein weckt das Herz zu Freud und Lust',
                        author: "Horaz"
                    },
                    {
                        quote: 'Wein ist Poesie in Flaschen.',
                        author: "Robert Louis Stevenson"
                    },
                    {
                        quote: 'Wein ist die Antwort. Was war die Frage?',
                        author: "Unbekannt"
                    },
                    {
                        quote: 'Ein guter Wein ist wie ein guter Film: Er dauert nur einen Moment, hinterlässt aber einen Geschmack im Mund.',
                        author: "Federico Fellini"
                    },
                    {
                        quote: 'Wo kein Wein ist, da ist auch keine Liebe.',
                        author: "Euripides"
                    },
                    {
                        quote: 'Ein Weinliebhaber ist jemand, der einen edlen Tropfen mit der richtigen Einstellung genießt.',
                        author: "Salvador Dalí"
                    }
                ]
            }
        },
        created () {
            this.getRandomQuotesNumber()
        },
        methods: {
            startTyping() {
                var quoteNumber = this.getRandomQuotesNumber()
                var quote = this.quotes[quoteNumber].quote
                let i = 0;
                let delay = 50
                const typeWriter = () => {
                    if (i < quote.length) {
                        this.typedText += quote.charAt(i);
                        i++;
                        setTimeout(typeWriter, delay);
                    } else {
                        this.author = "- " + this.quotes[quoteNumber].author
                    }
                };
                typeWriter();
            },
            getRandomQuotesNumber() {
                return Math.floor(Math.random() * this.quotes.length)
            },
            errorOuccured() {
                if (this.error) {
                    return "error"
                }
            },
            async login(e) {
                window.localStorage.clear()
                e.preventDefault()
                await DataService.login(this.email, this.password).then(async (response) =>  {
                    this.$store.commit('updateJwt', response.data.jwt)
                    await DataService.getUserData().then((response) => {
                        this.$store.commit('updateUserData', response.data)
                        if (response.data.language != null) {
                            this.$i18n.locale = response.data.language.languageKey
                        }
                    }).catch((err) => {
                        console.log(err)
                    })

                      DataService.getRatingAgencies().then((response) => {
                          this.$store.commit('setRatingAgencies', response.data)
                      }).catch((err) => {
                          console.log(err)
                      })
                    
                      DataService.getLanguages().then((response) => {
                          this.$store.commit('setLocales', response['data'])
                      }).catch((err) => {
                          console.log(err)
                      })

                    if (this.isInventoryOnly) {
                      console.log('InventoryOnly')
                      this.$router.push('/venues')
                    } else {
                        this.$router.push('/')
                    }
                }).catch((err) => {
                    console.log(err)
                    this.error = true
                    this.password = ''
                })
            },  
        },
        mounted: function() {
            this.startTyping()
        },
        computed: {
        ...mapGetters(['isInventoryOnly'])
      },
    }
</script>
<style scoped>
</style>